<template>
    <div class="">
        <LightBox
            :visible="showLightbox"
            :images="slides"
            :currentImage="currentImage"
            v-on:close="toggleLightbox()"
        />

        <div class="galerie-grid" :class="'galerie-layout-' + props.layout">
            <div
                class="galerie-col"
                :class="'galerie-col-' + index"
                v-for="(imgCol, index) in imgCols"
                :key="index"
            >
                <div
                    class="img-container fade-in"
                    v-for="(bild, index) in imgCol"
                    :key="index"
                    @click="toggleLightbox(bild)"
                >
                    <img
                        :style="{
                            'object-position': focalPoint(bild),
                        }"
                        :src="bild.url"
                        :alt="bild.alt"
                        class="cursor-pointer"
                        :class="{ 'rounded-2xl': CONFIG_OPTIONS.global.roundedCorners }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { AssetInterface } from "@gql-types/types.generated";
import LightBox from "./common/LightBox.vue";
import { CONFIG_OPTIONS } from "@src/globals";
import { focalPoint } from "../helpers/style.helper.ts";

const props = defineProps<{
    data: any;
    layout: number;
}>();

const showLightbox = ref(false);
const slides: AssetInterface[] = props.data;
const currentImage = ref<AssetInterface>({} as AssetInterface);

/** @param image If opening lightbox: image to render.
 *  @param image If closing lightbox: index of last image that was rendered (-> possibly focus/scroll to that image in parent)
 */
function toggleLightbox(image: AssetInterface | number = 0) {
    showLightbox.value = !showLightbox.value;
    if (typeof image === "number") return;
    currentImage.value = image;
}

let imgCols = [];

if (props.layout === 1) {
    imgCols = [[], []];
    props.data.forEach((bild, index) => {
        imgCols[index % 2].push(bild);
    });
} else if (props.layout === 2) {
    imgCols = [props.data];
} else if (props.layout === 3) {
    imgCols = [[], [], []];
    props.data.forEach((bild, index) => {
        if (index == 0) {
            imgCols[0].push(bild);
        } else if (index == 1) {
            imgCols[1].push(bild);
        } else if (index == 2) {
            imgCols[2].push(bild);
        } else if (index == 3) {
            imgCols[1].push(bild);
        } else {
            imgCols[(index - 4) % 3].push(bild);
        }
    });
}
</script>
